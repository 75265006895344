<template>
    <div class="dataAnalysis-container">
        <div class="top-wrapper">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb-student">
                <el-breadcrumb-item>数据分析</el-breadcrumb-item>
                <el-breadcrumb-item>{{analysisDetail.title}}</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button class="blue-btn" size="small" @click="goBack">返回</el-button>
        </div>
        <template v-if="Object.keys(analysisDetail).length > 0">
            <div class="dataAnalysis-item" v-for="(detailItem, detailKey, detailIndex) in analysisDetail.content" :key="`analysisDetail_${detailIndex}`">
                <div class="item-top">{{detailItem.title}}</div>
                <div class="item-content" v-html="detailItem.content"></div>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        filters: {
            analysisType(type) {
                const types = {
                    'portrayal_analyse': '人群画像分析',
                    'account_analyse': '账号分析',
                    'selection_analyse': '选品分析',
                    'video_analyse': '视频分析',
                }
                return types[type]
            }
        },
        data() {
            return {
                analysisId: Number(this.$route.query.id) || '',
                analysisDetail: {}
            }
        },
        mounted() {
            // if (this.analysisId) {
            this.getSingleAnalysis()
            // }
        },
        methods: {
            // 获取单个数据信息
            getSingleAnalysis() {
                let param = {
                    id: this.analysisId
                }
                this.$httpStudent.axiosGetBy(this.$api.analyse_getAnalyseDetail, param, (res) => {
                    if (res.code === 200) {
                        let tmp = {
                            title: res.data[0].title,
                            content: {}
                        }
                        if (res.data[0].portrayal_analyse) {
                            tmp.content.portrayal_analyse = JSON.parse(res.data[0].portrayal_analyse)
                        }
                        if (res.data[0].account_analyse) {
                            tmp.content.account_analyse = JSON.parse(res.data[0].account_analyse)
                        }
                        if (res.data[0].selection_analyse) {
                            tmp.content.selection_analyse = JSON.parse(res.data[0].selection_analyse)
                        }
                        if (res.data[0].video_analyse) {
                            tmp.content.video_analyse = JSON.parse(res.data[0].video_analyse)
                        }
                        this.analysisDetail = tmp
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            goBack() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style scoped lang="scss">
    .dataAnalysis-container {
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 100px);
        .top-wrapper {
            position: relative;
            .el-button {
                position: absolute;
                top: -9px;
                right: 0;
            }
        }
        .dataAnalysis-item {
            background: #fff;
            margin-top: 20px;
            border-radius: 10px;
            padding: 30px 40px;
            box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.09);
            .item-top {
                font-size: 18px;
                line-height: 1;
                padding-left: 16px;
                color: #3A3567;
                position: relative;
                padding-bottom: 20px;
                border-bottom: 1px solid #ddd;
                &:before {
                    content: '';
                    position: absolute;
                    width: 8px;
                    height: 15px;
                    background: #9ACC5B;
                    border-radius: 4px;
                    left: 0;
                }
            }
            .item-content {
                padding-top: 10px;
                ::v-deep img {
                    max-width: 100%;
                }
                ::v-deep a {
                    color: #1D33E1;
                    &:hover {
                        color: #5F55F1;
                    }
                }
                ::v-deep table {
                    border-collapse: collapse!important;
                    width: 100%!important;
                    border-color: transparent;
                    tr {
                        &:nth-child(even) {
                            td {
                                background: #fff;
                            }
                        }
                        &:nth-child(odd) {
                            td {
                                background: #E3E2F4;
                            }
                        }
                        th {
                            text-align: center;
                            padding: 12px 20px;
                            height: 20px!important;
                            line-height: 20px;
                            box-sizing: border-box;
                            font-size: 16px;
                            color: #fff;
                            white-space: nowrap;
                            background: #5F55F1;
                            font-weight: normal;
                        }
                        td {
                            text-align: center;
                            padding: 12px 20px;
                            font-size: 16px;
                            color: #3A3567;
                        }
                    }
                }
            }
        }
    }
</style>